import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取电子屏设备类型列表
 * @returns
 */
export function getElecDeviceModelList () {
  return request.post('/ooh-msp/v1/taskOther/getElecDeviceModelList')
}

/**
 * 查询任务主条目下全部反馈照片或者指定类型素材反馈照片
 * @param {Object} data 参数
 * @returns
 */
export function getTaskFeedbackAttachList (data) {
  return request.post('/ooh-msp/v1/taskOther/getTaskFeedbackAttachList', qs.stringify(data))
}

/**
 * 设置任务的回传照片
 * @param {Object} data 参数
 * @returns
 */
export function setTaskitemAttach (data) {
  return request.post('/ooh-msp/v1/taskOther/setTaskitemAttach', qs.stringify(data))
}
