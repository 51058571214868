<template>
  <div>
    <div class="m-b-10" v-if="deviceModelArray.length">
      <RadioGroup type="button" size="small" v-model="query.devicemodel" @on-change="changeProduct">
        <Radio :label="item.productId" v-for="item in deviceModelArray" :key="item.productId">{{ item.productName }}
        </Radio>
      </RadioGroup>
    </div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select v-model="assetId" size="small" :clearable="true" placeholder="资产" @on-change="handleChangeAsset">
          <Option v-for="item in companyAssetArray" :key="'asset_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.stationId" size="small" placeholder="站点" :clearable="true">
          <Option v-for="item in stationArray" :key="'station_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.position" size="small" placeholder="区域" :clearable="true">
          <Option v-for="item in positionArray" :key="'ps_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.pictureResource" size="small" placeholder="画面来源" :clearable="true">
          <Option v-for="item in pictureSourceArray" :key="'ps_' + item.value" :value="item.value">{{ item.name }}
          </Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.status" size="small" placeholder="任务状态" :clearable="true">
          <Option v-for="item in taskStatusArray" :key="'ps_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="7">
        <Input v-model="query.keyword" size="small" placeholder="资源编号、画面名称" />
      </i-col>
      <i-col span="2">
        <Button type="primary" icon="ios-search" size="small" @click="handleSearch">搜索</Button>
      </i-col>
    </Row>
    <Row class="m-b-5">
      <i-col span="12">
        <slot name="search_extra">
          <Checkbox v-if="[1, 7].includes(taskType)" v-model="query.queryNeedManualInterventionTask" class="m-r-5"
            @on-change="handleSearch">查看需人工干预任务</Checkbox>
          <Button class="m-r-5" size="small" type="primary" :disabled="btnSetActionDate"
            @click="handelSetActionDate(1)">设置作业时间</Button>
          <Button class="m-r-5" size="small" type="primary" :disabled="btnSetComp"
            @click="handleSetCompany">配置供应商</Button>
          <!-- <Button
            class="m-r-5"
            size="small"
            type="primary"
            v-if="[7,10].includes(taskType)"
            :disabled="selectedTaskItemIds.length===0"
            @click="handleSetUninstallPic"
          >设置下刊画面</Button> -->
          <!-- <Button
            class="m-r-5"
            type="primary"
            v-if="[1,7,10].includes(taskType)"
            :disabled="selectedTaskItemIds.length===0"
            @click="handleSetPictureSource"
          >设置画面来源</Button> -->
          <!-- <Button
            class="m-r-5"
            size="small"
            type="primary"
            v-if="taskType!=5&&isAuth('task_detail_adjustment_fee')"
            :disabled="selectedTaskItemIds.length===0"
            @click="handleSetCost"
          >工艺费用调整</Button> -->
          <Button class="m-r-5" size="small" type="success" :disabled="selectedTaskItemIds.length === 0"
            @click="handleDispatchTask">派送任务</Button>
          <Button class="m-r-5" size="small" type="error" :disabled="btnCancelDispatch"
            @click="handleCancelDispatch()">取消派送</Button>
          <Button v-if="approval" class="m-r-5" size="small" type="success" @click="handleSubmitApproval">发起审批</Button>
        </slot>
      </i-col>
      <i-col span="12">
        <div class="text-right">
          <Page size="small" show-sizer show-total :total="tableData.totalRow" :page-size="query.pageSize"
            :page-size-opts="pagesizeSetting" :current="query.pageNumber" @on-page-size-change='changePageSize'
            @on-change="changePage" />
        </div>
      </i-col>
    </Row>
    <p class="m-b-5 text-orange">提示：1.橙色标注任务需要进行“人工干预”操作处理。2.作业日期标注颜色的任务需视情况处理。</p>
    <div>
      <Table stripe max-height="620" size="small" :loading="tableLoading" :row-class-name="rowClassName"
        :data="tableData.list" :columns="tableColumns" @on-selection-change="selectionChange">
      </Table>
      <Row :gutter="8">
        <i-col span="12">
          <div class="m-t-5" style="line-height:25px;">当前已选中<span style="color:#ff9900;padding:0 3px">{{
      selectedTaskItemIds.length }}</span>条</div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page :total="tableData.totalRow" :page-size="query.pageSize" size="small" show-total show-elevator
              show-sizer :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' @on-change="changePage"
              :current="query.pageNumber"></Page>
          </div>
        </i-col>
      </Row>

      <Modal v-model="taskViewModal" width="800" footer-hide>
        <p slot="header" class="text-center">查看关联任务</p>
        <div class="m-b-5">
          <Table stripe size="small" :data="conflictTaskList" :columns="conflictColumns"></Table>
        </div>

      </Modal>
      <Modal v-model="visiblePreview" width="800">
        <p slot="header" class="text-center">{{ fileName }} {{ variableTitle }}</p>
        <div>
          <Row class="m-b-5">
          <i-col class="text-right">
            <Button size="small" type="success" @click="viewBigImage">查看原图</Button>
          </i-col>
        </Row>
        <img-light-box ref="imgLightBox" :showModal="visiblePreview" :sortIndex.sync="sortIndex"></img-light-box>
        </div>
        <div slot="footer" class="text-left">
          <div v-if="serialArray && serialArray.length">
            <div>
              <h4 class="workplatform-title m-t-10 m-b-20">对应序号</h4>
            </div>
            <div>
              <Tag color="primary" v-for="item in serialArray" :key="'serial_' + item">{{ item }}</Tag>
            </div>
          </div>
          <div v-else></div>
        </div>
      </Modal>
      <!-- <adjustment-fee
        ref="adjustmentFee"
        :taskCostModel="taskCostModel"
      ></adjustment-fee> -->
    </div>

    <!-- 测量任务的SVG套搞查看 -->
    <SvgMaintenanceTask ref="SvgMaintenanceTask" />
    <InstallCompletion v-if="isAuth('task_complete_manual') && [1, 7].includes(taskType)" ref="installCompletion"
      :onSuccess="refreshTableData" />
    <PendingTaskItem ref="pendingTaskItem" :taskItemId="taskitemId" :deviceId="curDeviceId" :taskIds="[taskId]"
      :taskitemIds="taskitemIds" :cancel="cancel" :onSuccessFun="getTaskitemPage" @confirmCancel="handleConfirmCancel">
    </PendingTaskItem>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { sysMixins } from '@/assets/mixins/sys'
import { commonMixins } from '@/assets/mixins/common'
import { companyMixin } from '@/assets/mixins/company'
import { formatEnable, formartMspTaskitemStatus, formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
// import { toMoney } from '@/utils/wnumb_own'

import { getTaskitemStatus, assignTaskItems, getTaskPosition, setActionDate, setReuseOldFile, revokeAssignTaskitems, tasktypeApproveProgress, startApprove } from '@/api/msp/taskitem'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { taskitemList, modifyActionDate, terminate } from '@/api/msp/conflict'
import { getRelatedTaskitem } from '@/api/msp/plan'
import { getDeviceList } from '@/api/msp/task'
import { getOtherTaskitemInfo } from '@/api/msp/hangup'
import { getElecDeviceModelList } from '@/api/msp/taskother'
import { getCodeList } from '@/api/msp/uninstall'

import ImgLightBox from '@/components/common/ImgVideoLightBox'
import SvgMaintenanceTask from '@/components/svg/SvgMaintenanceTask'
import InstallCompletion from './InstallCompletion'
import PendingTaskItem from '../common/PendingTaskItem'
// import AdjustmentFee from './AdjustmentFee.vue'

export default {
  mixins: [sysMixins, commonMixins, companyMixin],
  components: {
    ImgLightBox,
    SvgMaintenanceTask,
    InstallCompletion,
    PendingTaskItem
    // AdjustmentFee
  },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,
      actionDate: '',
      fileName: '',
      variableTitle: '', // 变量标题
      curShowFileArray: [],
      visiblePreview: false,
      sortIndex: 0,
      assetId: null,
      query: {
        assetIds: '',
        devicemodel: null,
        stationId: null,
        supplierCompanyId: null,
        status: null,
        position: null,
        pictureResource: null,
        showRelatedTask: false,
        notIncludeDraft: true,
        showCharge: true,
        queryAssignedAndExecutingTask: true,
        queryNeedManualInterventionTask: false,
        queryPublishingEndDate: true,
        showOrderInfo: false,
        keyword: '',
        pageSize: 15,
        pageNumber: 1
      },
      companyAssetArray: [],
      stationArray: [],
      positionArray: [],
      pagesizeSetting: [15, 50, 100, 200, 500],
      taskStatusArray: [],
      tableColumns: [],
      tableLoading: false,
      // tempSelectedTaskItem: [], // 临时存储分页保留之前选中的taskItemids
      tempSelectedTaskItemStatus: [], // 临时存储当前选中任务状态
      isReuseOldFile: false, // 是否使用已存在画面
      taskitemId: null,
      taskViewModal: false, // 关联任务查看Modal
      conflictTaskList: [], // 冲突任务数据
      conflictTaskitemId: null, // 操作冲突任务id
      conflictColumns: [
        { title: '资源编号', align: 'center', key: 'resourceCode' },
        { title: '工单来源', align: 'center', key: 'orderSource' },
        {
          title: '任务类型',
          align: 'center',
          key: 'taskitemTypeName',
          render: (h, params) => {
            return h('span', params.row.taskitemType === 1 ? `${params.row.taskitemTypeName} - ${params.row.adTypeName}` : params.row.taskitemTypeName)
          }
        },
        { title: '作业日期', align: 'center', key: 'actionDate' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formartMspTaskitemStatus(h, params.row.status, params.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            // 不执行， 执行中，已完成状态不可进行操作
            if (![-1, 3].includes(params.row.status)) {
              return h('div', [
                h('a', {
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.conflictTaskitemId = params.row.taskitemId
                      this.handelSetActionDate(2)
                    }
                  }
                }, '调整作业日期'),
                h('a', {
                  style: { display: 'block', color: '#ef4f4f' },
                  on: {
                    click: () => {
                      this.handleCancelConflictTask(params.row, 1)
                    }
                  }
                }, '任务不执行')
              ])
            }
          }
        }
      ],
      taskTypeShowColumns: new Map([
        [1, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'createCompanyName', 'supplierName', 'adTypeName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 上刊设置
        [2, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'createCompanyName', 'supplierName', 'adTypeName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 监播
        [3, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'createCompanyName', 'supplierName', 'adTypeName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 制作
        // [4, ['selection', 'resourceCode', 'domainName', 'actionDate', 'supplierName', 'status', 'remark', 'operate']], // 清洁任务
        [5, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'actionDate', 'actionFile', 'createCompanyName', 'adTypeName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 维修
        // [6, ['selection', 'resourceCode', 'domainName', 'actionDate', 'actionName', 'supplierName', 'status', 'remark', 'operate']], // 测量任务
        [7, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'createCompanyName', 'supplierName', 'adTypeName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 下刊
        [8, ['selection', 'domainName', 'actionDate', 'createCompanyName', 'supplierName', 'serviceCoastName', 'serviceItemCoast', 'userName', 'status', 'remark', 'operate']], // 巡检
        [9, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'createCompanyName', 'supplierName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 维护
        [10, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'createCompanyName', 'supplierName', 'adTypeName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']], // 画面填充
        [11, ['selection', 'resourceCode', 'assetName', 'domainName', 'actionDate', 'createCompanyName', 'supplierName', 'serviceCoastName', 'serviceItemCoast', 'status', 'remark', 'operate']] // 临时任务
      ]),
      approval: false, // 是否需要审批
      approvalRemark: '', // 审批备注
      deleteProduce: true,
      produceStatus: null,
      deviceModelArray: [],
      isActionDateErr: false, // 设置作业日期是否处于发布中档期内
      curDeviceId: null,
      taskitemIds: [],
      cancel: false, // 是否为取消派送操作
      elecDeviceIds: [],
      serialArray: [] // 画面序号集合
      // modifyCostModal: false,
      // taskCostModel: null
    }
  },
  created () {
    // 上下刊任务查询订单信息
    if ([1, 7].includes(this.taskType)) {
      this.query.showOrderInfo = true
    }
    this.init()
    this.getElecDeviceIds()
  },
  mounted () {
    this.tableColumns = this.getTableColumns()
  },
  destroyed () {
    this.isActionDateErr = false
    this.selectedTaskItemIds = []
    this.tempSelectedTaskItem = []
    this.$store.commit('set_task_item_data', {})
  },
  computed: {
    taskId () {
      return this.$store.state.workOrder.taskId
    },
    taskType () {
      return this.$store.state.workOrder.taskType
    },
    pictureSourceArray () {
      return this.$store.state.workOrder.pictureSourceArray
    },
    btnSetActionDate () {
      if (this.selectedTaskItemIds.length && this.tempSelectedTaskItemStatus.length === 1) {
        // 已派单不能进行设置作业日期
        if ([1, 2].includes(this.tempSelectedTaskItemStatus[0])) {
          return true
        }
        return false
      } else {
        return true
      }
    },
    btnSetComp () {
      if (this.selectedTaskItemIds.length && this.tempSelectedTaskItemStatus.length === 1) {
        // 已派单和已完成不能进行设置供应商
        if ([1, 2].includes(this.tempSelectedTaskItemStatus[0])) {
          return true
        }
        return false
      } else {
        return true
      }
    },
    btnCancelDispatch () {
      if (this.selectedTaskItemIds.length && this.tempSelectedTaskItemStatus.length === 1 && [1, 4, 5].includes(this.tempSelectedTaskItemStatus[0])) {
        // 已派单、待提交审批、审批中可进行取消派送
        return false
      } else {
        return true
      }
    },
    tableData () {
      const results = this.$store.state.workOrder.taskItemData
      if (results.list) {
        results.list.forEach(item => {
          item._disabled = false
          // 不执行， 执行中，审批中，已完成，挂起状态不可进行勾选操作
          if ([-1, 2, 3, 5].includes(item.status) || item.hangUpStatus === 2) {
            item._disabled = true
          } else {
            if (this.$store.state.workOrder.selectedTaskItemIds.some(x => x === item.id)) {
              item._checked = true
            }
          }
        })
      }
      return results
    },
    selectedTaskItemIds: {
      get () {
        return this.$store.state.workOrder.selectedTaskItemIds
      },
      set (val) {
        this.$store.commit('set_selected_task_item_ids', val)
      }
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.workOrder.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    tempSelectedTaskItem: {
      get () {
        return this.$store.state.workOrder.tempSelectedItem
      },
      set (val) {
        this.$store.commit('set_temp_selected_item', val)
      }
    }
  },
  methods: {
    ...mapActions(['getPictureSourceData', 'getTaskItemData']),
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        resourceCode: { title: this.taskType === 11 ? '任务名称' : '资源编号', key: 'resourceCode', minWidth: 100, align: 'center' },
        resourceType: { title: '资源类型', key: 'resourcetypeName', minWidth: 100, align: 'center' },
        assetName: { title: '所属资产', key: 'assetName', minWidth: 100, align: 'center' },
        domainName: { title: '所属站点', key: 'stationName', minWidth: 100, align: 'center' },
        typeName: { title: '任务类型', key: 'typeName', minWidth: 100, align: 'center' },
        side: { title: '面数', key: 'side', minWidth: 60, align: 'center' },
        createCompanyName: {
          title: '发起公司',
          key: 'createCompanyName',
          minWidth: 100,
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.createCompanyName || '/')
          }
        },
        skuName: {
          title: '单品',
          key: 'skuName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.skuId > -1 ? params.row.skuName : '/')
          }
        },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            const html = []
            if (new Date(params.row.actionDate) <= new Date(params.row.publishingEndDate) && params.row.status === 0 && [1, 7].includes(this.taskType) && !this.elecDeviceIds.includes(params.row.resourcetypeId)) {
              // 派单中状态，当前作业日期小于等于正在发布的档期结束日期。排除电子屏。
              html.push(
                h('Tooltip', {
                  style: {
                    cursor: 'pointer'
                  },
                  props: {
                    content: `此资源正在发布中的结束日期为：${params.row.publishingEndDate || '/'}，${params.row.publishingBrandName && params.row.publishingBrandName !== '' ? '品牌为“' + params.row.publishingBrandName + '”，' : ''}请重新选择作业日期！`,
                    placement: 'right',
                    maxWidth: 200,
                    transfer: true
                  }
                }, [
                  h('span', {
                    style: {
                      color: '#ff6600'
                    }
                  }, params.row.actionDate),
                  h('Icon', {
                    props: {
                      type: 'md-help-circle',
                      color: '#ff6600',
                      size: 14
                    }
                  })
                ])
              )
            } else {
              html.push(
                h('p', params.row.actionDate)
              )
            }

            html.push(
              h('Tag', {
                props: { color: params.row.actionDateModifyStatus === 1 ? 'default' : 'primary' }
              }, params.row.actionDateModifyStatusName)
            )
            return h('div', html)
          }
        },
        unitDuration: { title: '规格(秒)', key: 'unitDuration', align: 'center', minWidth: 80 },
        playTimes: { title: '投放次数', key: 'playTimes', align: 'center', minWidth: 100 },
        tduration: { title: '总时长(秒)', key: 'tduration', align: 'center', minWidth: 100 },
        serviceCoastName: {
          title: '成本项',
          key: 'serviceName',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.serviceName === '' ? '/' : params.row.serviceName)
          }
        },
        serviceItemCoast: {
          title: '计费',
          align: 'center',
          minWidth: 160,
          render: (h, params) => {
            if (params.row.taskitemCostVOList.length) {
              const html = []
              params.row.taskitemCostVOList.forEach(item => {
                html.push(h('p', `${item.chargeTypeName}:${item.cost}元/${item.unitName}`))
              })
              return h('div', html)
            } else {
              return h('span', '/')
            }
          }
        },
        adTypeName: { title: '用途', key: 'adTypeName', align: 'center', minWidth: 80 },
        launchTime: {
          title: '投放时间',
          align: 'center',
          minWidth: 140,
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        actionFile: {
          title: '作业画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              if (params.row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFiles
                      }
                      this.sortIndex = 0
                      this.fileName = params.row.stationName
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFiles
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.domainName} ${params.row.code}`
                          this.variableTitle = ''
                          this.curShowFileArray = params.row.taskitemFiles
                          this.$refs.imgLightBox.init(postData)
                          this.handleCurFileSerial()
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        actionName: {
          title: '作业名称',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            const name = Array.from(new Set(params.row.taskitemFiles.map(x => x.fileName))).toString()
            return h('Tooltip', {
              props: {
                content: name,
                transfer: true,
                'max-width': '200px'
              }
            }, name.length > 12 ? name.substr(0, 11) + '...' : name)
          }
        },
        picturesource: {
          title: '画面来源',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.picturesourceName),
              params.row.isReuseOldFile ? h('p', {
                style: { color: '#ff9900' }
              }, '使用已有画面') : null
            ])
          }
        },
        existProduceFile: {
          title: '已有制作素材',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return formatEnable(h, params.row.existProduceFile)
          }
        },
        supplierName: {
          title: '供应商',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.companyName)
          }
        },
        userName: {
          title: '巡检人员',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.userName)
          }
        },
        status: {
          title: '状态',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            const html = [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName),
              formartMspTaskitemRephotoStatus(h, params.row.rephoto)
            ]
            if (params.row.hangUpStatus === 2) { // 挂起
              html.push(h('div', [
                h('Tag', {
                  props: {
                    color: 'warning'
                  }
                }, '已挂起')
              ]))
            }
            return h('div', html)
          }
        },
        remark: {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark} ${params.row.extra}`
            return h('Tooltip', {
              props: {
                content: remark,
                transfer: true,
                'max-width': '200px'
              }
            }, remark.length > 12 ? remark.substring(0, 11) + '...' : remark)
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 100,
          render: (h, params) => {
            var html = []
            if (params.row.type === 6) { // 测量任务
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$refs.SvgMaintenanceTask.initPageData(params.row, true)
                  }
                }
              }, '测量详情'))
            } else {
              if (params.row.taskitemAttachs && params.row.taskitemAttachs.length) {
                html.push(h('a', {
                  style: { marginRight: '5px', cursor: 'pointer' },
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemAttachs
                      }
                      this.fileName = `${params.row.domainName} ${params.row.code}`
                      this.variableTitle = params.row.finishTime ? `（完成时间：${params.row.finishTime}）` : ''
                      this.curShowFileArray = params.row.taskitemAttachs
                      this.$refs.imgLightBox.init(postData)
                      this.handleCurFileSerial()
                      this.visiblePreview = true
                    }
                  }
                }, '照片反馈'))
              }
              if (params.row.conflictTag > 0) {
                html.push(h('a', {
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.taskViewModal = true
                      this.conflictTaskList = []
                      this.taskitemId = params.row.id
                      this.getConflictTaskList(params.row.id)
                    }
                  }
                }, '关联任务'))
                // 不执行，已完成状态不可进行操作
                if (![-1, 3].includes(params.row.status)) {
                  html.push(
                    h('a', {
                      style: { display: 'block', color: '#ef4f4f' },
                      on: {
                        click: () => {
                          this.handleCancelConflictTask(params.row, 2)
                        }
                      }
                    }, '任务不执行'))
                }
              }
              // if (this.taskType === 8) {
              //   if (!params.row.qualityTesting) {
              //     html.push(h('a', {
              //       // 非巡检任务操作
              //       on: {
              //         click: () => {
              //           this.handelCheck(params.row)
              //         }
              //       }
              //     }, '核查'))
              //   }
              // } else {
              if ([1, 7].includes(this.taskType)) { // 上下刊
                if (params.row.status === 0 && params.row.existProduceFile) {
                  html.push(h('a', {
                    style: { display: 'block' },
                    on: {
                      click: () => {
                        this.isReuseOldFile = params.row.isReuseOldFile
                        this.taskitemId = params.row.id
                        this.handelSetResuseOldFile()
                      }
                    }
                  }, '画面配置'))
                }
                if (params.row.isManualIntervention === true && params.row.status !== 3) { // 排除已完成
                  html.push(h('a', {
                    style: { display: 'block', color: '#2D8cF0' },
                    on: {
                      click: () => {
                        this.taskitemId = params.row.id
                        this.handlePendingTask(params.row)
                        // this.handelSetResuseOldFile()
                      }
                    }
                  }, '人工干预'))
                }
              }
              if (params.row.status === 1) {
                html.push(h('a', {
                  // 非巡检任务操作
                  style: { display: 'block', color: '#ef4f4f' },
                  on: {
                    click: () => {
                      this.handelCancelDispatch(params.row)
                    }
                  }
                }, '取消派送'))
              }
              if ([1, 7].includes(this.taskType) && [1, 2].includes(params.row.status) && this.isAuth('task_complete_manual')) { // 已派单和执行中的上刊和下刊任务
                html.push(h('a', {
                  // 非巡检任务操作
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.handleCompletionInstall(params.row)
                    }
                  }
                }, '完成任务'))
              }
            }
            // }
            return h('div', html)
          }
        }
      }

      const data = []
      var showColumns = this.taskTypeShowColumns.get(this.taskType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    init () {
      // if (![8, 9, 11].includes(this.taskType)) {
      //   this.getTaskProductData()
      // } else {
      //   this.getTaskitemPage()
      // }
      this.getTaskitemPage()
      this.getCompanyAssetArray()
      this.getPositionData()
      this.getTaskStatusData()
      this.getPictureSourceData()

      this.checkIsApproval()
    },
    rowClassName (row) {
      // 上下刊存在需要手动处理“挂起”操作的任务，数据行进行颜色标注，排除已完成状态
      if ([1, 7].includes(this.taskType) && row.isManualIntervention === true && row.status !== 3) {
        return 'demo-table-error-row'
      }
    },
    getTaskProductData () {
      getDeviceList({ taskId: this.taskId }).then(res => {
        if (res && !res.errcode) {
          this.deviceModelArray = res
          if (this.deviceModelArray && this.deviceModelArray.length) {
            this.query.devicemodel = this.deviceModelArray[0].productId
          }
          this.changeProduct()
        } else {
          this.deviceModelArray = []
          this.query.devicemodel = null
        }
      })
    },
    changeProduct () {
      this.assetId = null
      this.query.pageNumber = 1
      this.query.assetIds = ''
      this.query.stationId = null
      this.query.position = null
      this.query.pictureResource = null
      this.query.status = null
      this.query.keyword = ''
      this.selectedTaskIds = []
      this.selectedResourceIds = []
      this.tempSelectedTaskItem = []
      // this.query.removeSetted = false
      // this.$store.commit('set_selected_productId', this.query.productId)

      this.getTaskitemPage()
    },
    getTaskitemPage () {
      this.tableLoading = true
      this.query.showRelatedTask = [1, 7].includes(this.taskType)
      this.query.assetIds = this.assetId ? JSON.stringify([this.assetId]) : ''
      this.getTaskItemData(this.query).finally(() => { this.tableLoading = false })
    },
    refreshTableData () {
      this.getTaskitemPage()
      this.$store.dispatch('getStatusStatisticData')
    },
    /**
     * 获取任务状态类型
     */
    getTaskStatusData () {
      getTaskitemStatus({ taskType: this.taskType }).then(res => {
        if (res && !res.errcode) {
          this.taskStatusArray = res
        } else {
          this.taskStatusArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.isActionDateErr = false
      this.selectedTaskItemIds = []
      this.selectedResourceIdArray = []
      this.tempSelectedTaskItemStatus = []
      this.tempSelectedTaskItem = []
      this.getTaskitemPage()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.isActionDateErr = false
      this.selectedTaskItemIds = []
      this.selectedResourceIdArray = []
      this.tempSelectedTaskItemStatus = []
      this.tempSelectedTaskItem = []
      this.getTaskitemPage()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      // this.selectedTaskItemIds = []
      this.getTaskitemPage()
    },
    handleChangeAsset () {
      this.query.pageNumber = 1
      this.isActionDateErr = false
      this.selectedTaskItemIds = []
      this.selectedResourceIdArray = []
      this.tempSelectedTaskItemStatus = []
      this.tempSelectedTaskItem = []
      this.getStationData()
      this.getTaskitemPage()
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: this.assetId ? JSON.stringify([this.assetId]) : '' }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    selectionChange (selection) {
      this.isActionDateErr = false
      this.selectedTaskItemIds = []
      this.selectedResourceIdArray = []
      // this.taskCostModel = null
      let tempSelectedTaskStatus = []
      let tempSelectSupplierArray = []
      let tempCost = []

      // // 工艺&费用调整所需参数
      // if (selection.length) {
      //   this.taskCostModel = { taskitemType: selection[0].type, material: selection[0].material, process: selection[0].process, resourceTypeId: this.query.devicemodel, supplierCompanyId: selection[0].taskitemLabor.companyId || null }
      // }

      // 最终选中的所有taskItem
      const curSelection = selection.map(x => {
        return { id: x.id, resourceId: x.resourceId, deviceId: x.deviceId, status: x.status, type: x.type, devicemodel: x.devicemodel, supplierCompanyId: x.taskitemLabor.companyId, actionDate: x.actionDate, publishingEndDate: x.publishingEndDate, resourcetypeId: x.resourcetypeId }
      })
      this.handelPageSelections(this.tempSelectedTaskItem, curSelection, this.query.pageNumber)
      this.tempSelectedTaskItem.forEach(item => {
        this.selectedTaskItemIds = this.selectedTaskItemIds.concat(item.existSelections.map(x => x.id))
        this.selectedResourceIdArray = this.selectedResourceIdArray.concat(item.existSelections.map(x => x.deviceId))
        tempSelectedTaskStatus = tempSelectedTaskStatus.concat(item.existSelections.map(x => x.status))
        tempCost = tempCost.concat(item.existSelections.map(x => { return { type: x.type, devicemodel: x.devicemodel } }))
        tempSelectSupplierArray = tempSelectSupplierArray.concat(item.existSelections.map(x => x.supplierCompanyId))

        // 筛选是否设置作业日期在发布中档期内
        this.isActionDateErr = this.isActionDateErr || item.existSelections.some(x => (new Date(x.actionDate) <= new Date(x.publishingEndDate) && !this.elecDeviceIds.includes(x.resourcetypeId)))
      })

      // 选择第一个选中项的供应商id，用于设置供应商默认值的填充
      this.$store.commit('set_selected_supplier_id', tempSelectSupplierArray[0])

      // 组装任务类型和设备类型数据
      const costTypes = []
      tempCost.forEach(item => {
        if (!costTypes.some(x => x.costType === item.type)) {
          const obj = {
            costType: item.type,
            deviceModelList: Array.from(new Set(tempCost.filter(f => f.type === item.type).map(m => m.devicemodel)))
          }
          costTypes.push(obj)
        }
      })
      this.$store.commit('set_task_cost_type_array', costTypes)

      // 处理选中的任务状态
      this.tempSelectedTaskItemStatus = Array.from(new Set(tempSelectedTaskStatus))

      if (this.tempSelectedTaskItemStatus.length > 1) {
        this.isActionDateErr = false
        this.selectedTaskItemIds = []
        this.selectedResourceIdArray = []
        this.tempSelectedTaskItemStatus = []
        this.$Notice.warning({ desc: '请选择相同状态的任务进行批量操作' })
      }
    },
    getPositionData () {
      getTaskPosition({ taskId: this.taskId }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        }
      })
    },
    /**
     * 设置作业日期
     * opType，1：设置作业日期，2：调整关联任务作业日期
     */
    handelSetActionDate (opType) {
      // this.actionDateModal = true
      // this.setShowLeftBlock(true)
      // this.setLeftComponent('ActionDateView')
      this.$Modal.confirm({
        title: '设置作业日期',
        props: { width: 400 },
        render: (h, params) => {
          return h('DatePicker', {
            style: { width: '100%' },
            props: {
              value: this.actionDate,
              size: 'small',
              type: 'date',
              placeholder: '请选择作业日期'
            },
            on: {
              input: (val) => {
                this.actionDate = val
              }
            }
          })
        },
        onOk: () => {
          if (!this.actionDate) {
            this.$Notice.warning({ desc: '请选择作业日期' })
            return false
          }
          const date = new Date(this.actionDate)
          const actionDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

          switch (opType) {
            case 1:
              setActionDate({ actionDate: actionDate, taskitemIds: JSON.stringify(this.selectedTaskItemIds) }).then(res => {
                this.actionDateModal = false
                if (res && res.errcode === 0) {
                  this.actionDate = ''
                  this.isActionDateErr = false
                  this.selectedTaskItemIds = []
                  this.selectedResourceIdArray = []
                  this.tempSelectedTaskItemStatus = []
                  this.tempSelectedTaskItem = []
                  this.getTaskitemPage()
                  this.$Notice.success({ desc: '操作成功' })
                }
              })
              break
            case 2: // 编辑关联任务作业日期
              modifyActionDate({ newActionDate: actionDate, taskitemId: this.conflictTaskitemId }).then(res => {
                this.actionDateModal = false
                if (res && res.errcode === 0) {
                  this.actionDate = ''
                  this.taskViewModal = false
                  this.conflictTaskitemId = null
                  this.getTaskitemPage()
                  this.$Notice.success({ desc: '操作成功' })
                }
              })
              break
          }
        }
      })
    },
    /**
     * 设置供应商
     */
    handleSetCompany () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('SetCompany')
    },
    /**
     * 设置画面来源
     */
    handleSetPictureSource () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('SetPictrueSource')
    },
    /**
     * 设置下刊画面
     */
    // handleSetUninstallPic () {
    //   this.setShowLeftBlock(true)
    //   this.$store.commit('set_uninstall_pic_type', 2)
    //   this.$store.commit('set_left_component', 'UninstallSetScreen')
    // },
    /**
     * 派发任务
     */
    handleDispatchTask () {
      if (this.taskType === 7) {
        // 下刊任务
        this.onDispatchUninstall()
      } else {
        // 其他任务
        if (this.isActionDateErr && this.taskType === 1) { // 上刊任务
          this.$Notice.warning({ desc: '派发任务中，存在设置的作业日期处于正在发布中的档期内，请重新设置作业日期后，再次派发任务。', duration: 8 })
          return
        }
        this.onDispatchTask()
      }
    },
    onDispatchTask (content = '确定要派发所选任务？') {
      this.$Modal.confirm({
        title: '操作提示',
        content: content,
        onOk: () => {
          const postData = {
            taskId: this.taskId,
            taskitemIds: JSON.stringify(this.selectedTaskItemIds)
          }
          assignTaskItems(postData).then(res => {
            if (res && res.errcode === 0) {
              this.isActionDateErr = false
              this.selectedTaskItemIds = []
              this.selectedResourceIdArray = []
              this.tempSelectedTaskItemStatus = []
              this.tempSelectedTaskItem = []
              this.getTaskitemPage()
              this.$store.dispatch('getStatusStatisticData')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 派送下刊任务
     */
    async onDispatchUninstall () {
      let content = '确定要派发所选任务？'
      const result = await getCodeList({ taskitemIds: JSON.stringify(this.selectedTaskItemIds) })
      if (result && !result.errcode) {
        content = result.length ? `当前已选择的任务项中，资源编号为“${result.toString()}的任务项，与当前在刊任务信息不一致，确定要派发所选任务？”` : content
      }
      this.onDispatchTask(content)
    },
    /**
     * 取消单个任务
     */
    handelCancelDispatch (params) {
      this.handleCancelDispatch(params)
      // this.$Modal.confirm({
      //   title: '操作提示',
      //   content: '确定要取消该任务',
      //   props: { width: 400 },
      //   onOk: () => {
      //     revokeAssignTaskitem({ taskitemId: params.id }).then(res => {
      //       if (res && res.errcode === 0) {
      //         this.getTaskitemPage()
      //         this.$Notice.success({ desc: '操作成功' })
      //       }
      //     })
      //   }
      // })
    },
    /**
     * 批量取消派送任务
     */
    handleCancelDispatch (data) {
      this.cancel = true
      this.taskitemIds = []
      if (!data) {
        if (this.selectedTaskItemIds.length === 0 || ![1, 4, 5].includes(this.tempSelectedTaskItemStatus[0]) || this.tempSelectedTaskItemStatus.length > 1) {
          this.$Notice.warning({ title: '非法操作', desc: '可能原因：1.未选择任务，2.不是已派单任务，3.选中任务不是相同状态' })
          return false
        }
        this.taskitemIds = this.selectedTaskItemIds
      } else {
        this.taskitemIds = [data.id]
      }
      // 取消派单前获取是否存在需要处理的任务
      const postData = {
        pageNumber: 1,
        pageSize: 5,
        taskIds: JSON.stringify([this.taskId]),
        taskitemIds: JSON.stringify(this.taskitemIds)
      }

      getOtherTaskitemInfo(postData).then(res => {
        if (res && res.list && res.list.length) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '当前存在需要人工干预的任务项，点击“确定”按钮，显示需要人工干预的任务项详情。',
            onOk: () => {
              // 弹窗处理界面
              this.$nextTick(() => {
                this.$refs.pendingTaskItem.init()
              })
            }
          })
        } else {
          this.handleConfirmCancel()
        }
      })
    },
    /**
     * 直接取消派送
     */
    handleConfirmCancel () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要取消所选任务',
        props: { width: 400 },
        onOk: () => {
          revokeAssignTaskitems({ taskitemIds: JSON.stringify(this.taskitemIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.isActionDateErr = false
              this.selectedTaskItemIds = []
              this.selectedResourceIdArray = []
              this.tempSelectedTaskItemStatus = []
              this.tempSelectedTaskItem = []
              this.getTaskitemPage()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 巡检
     */
    handelCheck (params) {
      this.setLeftComponent('SetInspection')
      this.selectedTaskItemIds = [params.id]
    },
    /**
     * 使用已有画面操作
     */
    handelSetResuseOldFile () {
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('Checkbox', {
            props: {
              value: this.isReuseOldFile
            },
            on: {
              input: (val) => {
                this.isReuseOldFile = val
              }
            }
          }, '使用已有制作画面')
        },
        onOk: () => {
          setReuseOldFile({ taskitemId: this.taskitemId, isReuseOldFile: this.isReuseOldFile }).then(res => {
            if (res && res.errcode === 0) {
              this.getTaskitemPage()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    /**
     * 检查是否配置审批
     */
    checkIsApproval () {
      tasktypeApproveProgress({ taskType: this.taskType }).then(res => {
        if (res && !res.errcode) {
          this.approval = res
        }
      })
    },
    handleSubmitApproval () {
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('div', [
            h('p', { class: 'm-b-10' }, '确定要发起审批？'),
            h('Input', {
              props: {
                type: 'textarea',
                rows: 3,
                value: this.approvalRemark,
                autofocus: true,
                placeholder: '备注，非必填。'
              },
              on: {
                input: (val) => {
                  this.approvalRemark = val
                }
              }
            })
          ])
        },
        onOk: () => {
          startApprove({ taskId: this.taskId, remark: this.approvalRemark }).then(res => {
            if (res && res.errcode === 0) {
              this.getTaskitemPage()
              this.$store.dispatch('getStatusStatisticData')
              this.approvalRemark = ''
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    // 获取冲突任务列表
    getConflictTaskList (taskitemId) {
      taskitemList({ taskitemId }).then(res => {
        if (res && !res.errcode) {
          this.conflictTaskList = res
        }
      })
    },
    /**
     * 取消指定关联任务
     * tag:1,处理关联任务；2.处理当前任务
     */
    handleCancelConflictTask (params, tag = 1) {
      if (tag === 1) {
        this.produceStatus = params.produceStatus
      } else {
        getRelatedTaskitem({ taskitemId: params.id, taskitemType: params.type }).then(res => {
          if (res && !res.errcode) {
            this.produceStatus = res.status
          } else {
            this.produceStatus = null
          }
        })
      }
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          if (this.produceStatus != null && ![-1, 2, 3].includes(this.produceStatus)) {
            return h('div', [
              h('p', {
                style: { 'padding-bottom': '10px' }
              }, '当前存在制作任务，确定要取消制作任务？'),
              h('Checkbox', {
                props: {
                  value: this.deleteProduce
                },
                on: {
                  input: (val) => {
                    this.deleteProduce = val
                  }
                }
              }, '取消制作任务')
            ])
          } else {
            this.deleteProduce = false
            return h('span', '确定要取消该任务？')
          }
        },
        onOk: () => {
          terminate({ taskitemId: tag === 1 ? params.taskitemId : params.id, relatedTaskitemId: tag === 1 ? this.taskitemId : null, deleteProduce: this.deleteProduce }).then(res => {
            if (res && res.errcode === 0) {
              this.taskViewModal = false
              this.getTaskitemPage()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 手动完成上刊
     */
    handleCompletionInstall (params) {
      this.$refs.installCompletion.showModal(params.id)
    },
    handlePendingTask (params) {
      this.cancel = false
      this.curDeviceId = params.deviceId
      this.taskitemIds = []
      this.$nextTick(() => {
        this.$refs.pendingTaskItem.init()
      })
    },
    /**
     * 获取电子屏id集合
     */
    getElecDeviceIds () {
      this.elecDeviceIds = []
      getElecDeviceModelList().then(res => {
        if (res && !res.errcode) {
          this.elecDeviceIds = res
        }
      })
    },
    /**
     * 获取当前画面所在的梯牌号
     */
    handleCurFileSerial () {
      this.serialArray = this.curShowFileArray[this.sortIndex].serials ? JSON.parse(this.curShowFileArray[this.sortIndex].serials) : []
    }
    // /**
    //  * 工艺费用调整
    //  */
    // handleSetCost () {
    //   this.$refs.adjustmentFee.initData()
    //   // this.modifyCostModal = true
    // }
  },
  sortIndex: {
    deep: true,
    immediate: true,
    handler (val) {
      // this.variableTitle = this.currentFileArray[val].uploadTime ? `（上传时间：${this.currentFileArray[val].uploadTime}）` : ''
      this.handleCurFileSerial()
    }
  }
}
</script>
<style>
.ivu-table .demo-table-error-row td {
  background-color: #da8247 !important;
  color: #fff;
}
</style>
