import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询任务关联任务
 * @param {Object} data
 */
export function getTaskitemRelatedInfo (data) {
  return request({
    url: '/ooh-msp/v1/hangup/getTaskitemRelatedInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 设置任务挂起或继续执行
 * @param {Object} data
 */
export function setTaskitemHangUpOrGoOn (data) {
  return request({
    url: '/ooh-msp/v1/hangup/setTaskitemHangUpOrGoOn',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 分页查询被挂起任务列表
 * @param {Object} data
 */
export function getHangUpTaskitemPage (data) {
  return request({
    url: '/ooh-msp/v1/hangup/getHangUpTaskitemPage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 标注挂起不执行任务为已完成
 * @param {Object} data
 */
export function setHangUpTaskitemFinish (data) {
  return request({
    url: '/ooh-msp/v1/hangup/setHangUpTaskitemFinish',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 解除任务挂起状态
 * @param {Object} data
 */
export function unHangUpTaskitem (data) {
  return request({
    url: '/ooh-msp/v1/hangup/unHangUpTaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 上刊任务取消时，查询关联挂起任务和其他已派单未完成的上刊任务
 * @param {Object} data
 */
export function getOtherTaskitemInfo (data) {
  return request({
    url: '/ooh-msp/v1/hangup/getOtherTaskitemInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
