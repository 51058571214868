<template>
  <div>
    <Modal v-model="modalShow" width="70%" :footer-hide="!cancel">
      <p slot="header" class="text-center">人工干预任务列表</p>
      <div style="max-height: 600px; overflow-y: scroll;">
        <p v-if="cancel" class="m-b-5 text-orange">提示：取消派送操作，若需进行人工干预，请在“需要人工干预列表”进行操作后，再点击“确定”按钮；若直接取消派送，请点击“确定”按钮。</p>
        <Card class="m-b-10" v-for="(item, index) in showList" :key="index">
          <div v-if="item.mainTaskitem">
            <h4 class="workplatform-title m-t-10 m-b-10">当前任务基础信息</h4>
            <Row :gutter="8" class="m-b-10">
              <i-col span="6">
                <span class="title">任务名称</span>{{ item.mainTaskitem.taskName }}
              </i-col>
              <i-col span="6">
                <span class="title">编号</span>{{ item.mainTaskitem.code }}
              </i-col>
              <i-col span="6">
                <span class="title">站点</span>{{ item.mainTaskitem.stationName }}
              </i-col>
              <i-col span="6">
                <span class="title">用途</span>{{ item.mainTaskitem.adTypeName }}
              </i-col>
            </Row>
            <Row :gutter="8" class="m-b-10">
              <i-col span="6">
                <span class="title">作业日期</span>{{ item.mainTaskitem.actionDate }}
              </i-col>
              <i-col span="6">
                <span class="title">任务类型</span>{{ item.mainTaskitem.taskitemTypeName }}
              </i-col>
              <i-col span="6">
                <span class="title">状态</span>{{ item.mainTaskitem.statusName }}
              </i-col>
              <i-col span="6">
                <span class="title">供应商</span>{{ item.mainTaskitem.supplierName }}
              </i-col>
            </Row>
          </div>

          <div class="m-b-10">
            <h4 class="workplatform-title m-t-10 m-b-10">需要人工干预任务列表</h4>
            <Table stripe size="small" :columns="tableColumns" :data="item.uninstallOrFillTaskitemList"></Table>
          </div>
          <div class="m-b-10">
            <h4 class="workplatform-title m-t-10 m-b-10">关联任务列表</h4>
            <Table stripe size="small" :columns="tableColumns2" :data="item.installTaskitemList"></Table>
          </div>
        </Card>
        <div class="text-center" v-if="cancel">
          <Button size="small" type="primary" v-if="showList.length < total" @click="handleLoadingMore">加载更多</Button>
          <Button size="small" type="primary" v-else-if="query.pageNumber !== 1 && showList.length === total"
            @click="handleHideMore">隐藏更多</Button>
        </div>
      </div>
      <div v-if="cancel" slot="footer">
        <Button type="text" class="m-r-5" @click="modalShow = false">取消</Button>
        <Button type="primary" @click="handleCancel">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getTaskitemRelatedInfo, setTaskitemHangUpOrGoOn, getOtherTaskitemInfo, unHangUpTaskitem } from '@/api/msp/hangup'
export default {
  props: {
    deviceId: [Number],
    taskItemId: [Number],
    taskIds: [Array], // 取消派送需要的主任务id集合
    taskitemIds: [Array], // 取消派送需要的任务id
    cancel: { // 是否为取消派送操作
      type: Boolean,
      default: false
    },
    onSuccessFun: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      modalShow: false,
      // mainTaskInfo: {},
      // installTaskitemList: [], // 关联的上刊任务
      // uninstallOrFillTaskitemList: [], // 需要处理的任务
      query: {
        pageNumber: 1,
        pageSize: 5,
        taskIds: '',
        taskitemIds: ''
      },
      total: 0,
      showList: [],
      tableColumns: [
        // { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '任务名称', key: 'taskName', minWidth: 100, align: 'center' },
        { title: '任务类型', key: 'taskitemTypeName', minWidth: 100, align: 'center' },
        { title: '资源编号', key: 'code', minWidth: 100, align: 'center' },
        { title: '站点', key: 'stationName', minWidth: 100, align: 'center' },
        { title: '作业日期', key: 'actionDate', minWidth: 100, align: 'center' },
        { title: '用途', key: 'adTypeName', minWidth: 100, align: 'center' },
        { title: '状态', key: 'statusName', minWidth: 100, align: 'center' },
        { title: '挂起状态', key: 'hangUpStatusName', minWidth: 100, align: 'center' },
        { title: '供应商', key: 'supplierName', minWidth: 100, align: 'center' },
        {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const html = []
            if (this.cancel) {
              html.push(h('a', {
                style: { display: 'block' },
                on: {
                  click: () => {
                    this.hnadleConfirmHangup(params.row)
                  }
                }
              }, '取消挂起'))
            } else {
              html.push(h('a', {
                style: { display: 'block' },
                on: {
                  click: () => {
                    this.handleConfirm(1, params.row)
                  }
                }
              }, '继续执行'),
              h('a', {
                style: { display: 'block', color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handleConfirm(2, params.row)
                  }
                }
              }, '挂起任务'))
            }
            return h('div', html)
          }
        }
      ],
      tableColumns2: [
        { title: '任务名称', key: 'taskName', minWidth: 100, align: 'center' },
        { title: '任务类型', key: 'taskitemTypeName', minWidth: 100, align: 'center' },
        { title: '资源编号', key: 'code', minWidth: 100, align: 'center' },
        { title: '站点', key: 'stationName', minWidth: 100, align: 'center' },
        { title: '作业日期', key: 'actionDate', minWidth: 100, align: 'center' },
        { title: '用途', key: 'adTypeName', minWidth: 100, align: 'center' },
        { title: '状态', key: 'statusName', minWidth: 100, align: 'center' },
        { title: '供应商', key: 'supplierName', minWidth: 100, align: 'center' }
      ]
    }
  },
  methods: {
    init () {
      this.modalShow = true
      this.showList = []
      this.total = 0

      if (this.cancel) {
        // 取消时需要的人工干预
        this.query.pageNumber = 1
        this.query.taskIds = JSON.stringify(this.taskIds)
        this.query.taskitemIds = JSON.stringify(this.taskitemIds)
        this.getOtherTaskData()
      } else {
        this.getTaskData()
      }
    },
    // 派单时需要人工干预调用
    getTaskData () {
      const postData = {
        mainTaskitemId: this.taskItemId,
        deviceId: this.deviceId
      }
      getTaskitemRelatedInfo(postData).then(res => {
        if (res && !res.errcode) {
          this.showList.push(res)
        }
      })
    },
    // 取消派送时调用
    getOtherTaskData () {
      getOtherTaskitemInfo(this.query).then(res => {
        if (res && !res.errcode) {
          this.showList = this.showList.concat(res.list)
          this.total = res.totalRow
        }
      })
    },
    /**
     * 加载更多
     */
    handleLoadingMore () {
      this.query.pageNumber++
      this.getOtherTaskData()
    },
    /**
     * 隐藏更多
     */
    handleHideMore () {
      this.query.pageNumber = 1
      this.showList = []
      this.getOtherTaskData()
    },
    // selectionChange (selection) {
    //   this.selectedTaskItemIds = selection.map(x => x.taskitemId)
    // },
    handleConfirm (type, params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: `确定要${type === 1 ? '继续执行' : '挂起'}所选任务`,
        onOk: () => {
          const postData = {
            hangUpOrGoOn: type,
            hangUpRelatedId: params.mainTaskitemId,
            hangUpTaskitemId: params.taskitemId
          }
          setTaskitemHangUpOrGoOn(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.onSuccessFun()
            }
          }).finally(() => {
            this.modalShow = false
          })
        }
      })
    },
    /**
     * 取消挂起
     */
    hnadleConfirmHangup (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要取消挂起？',
        onOk: () => {
          unHangUpTaskitem({ taskitemId: params.taskitemId }).then(res => {
            if (res && res.errcode === 0) {
              // 取消挂起成功后，清空旧数据，获取新数据
              this.showList = []
              this.getOtherTaskData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 确认取消派送
     */
    handleCancel () {
      this.$emit('confirmCancel', 1)
      this.modalShow = false
    }
  }
}
</script>

<style></style>
