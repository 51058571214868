import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取任务冲突或关联任务集合
 * @param {Object} data
 */
export function taskitemList (data) {
  return request(
    {
      url: '/ooh-msp/v1/conflict/taskitemlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 修改作业日期
 * @param {Object} data
 */
export function modifyActionDate (data) {
  return request(
    {
      url: '/ooh-msp/v1/conflict/modifyactiondate',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 任务取消执行,若任务存在制作任务且未开始执行，则会将制作任务一并取消执行。若当前任务已开启审批流程，则将其移除审批任务
 * @param {Object} data
 */
export function terminate (data) {
  return request(
    {
      url: '/ooh-msp/v1/conflict/terminate',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
